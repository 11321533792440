<template lang="pug">
  .generalBackground
    Header
    b-container.p-5(fluid="true")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.install icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.install) Instal·la la plataforma Tygron
          b-collapse.collapsed(id="install")
            p El joc Edible City està desenvolupat sobre la plataforma Tygron. Per tant, el primer pas és instal·lar el programa al teu ordinador. Abans d'instal·lar-lo, comprova que el teu ordinador compleix&nbsp;
              a(href="https://support.tygron.com/wiki/Requirements")
              a(href="https://support.tygron.com/wiki/Requirements") el requeriments de hardware.
            p Un cop comprovat, pots descarregar l'instal·lador
              a(href="https://engine.tygron.com/")  aquí.
            b-img.m-3(src="../images/tygron_engine.png" center thumbnail)
            p A la pàgina, hauria d'aparèixer automàticament l'instal·lador per al teu sistema operatiu. Un cop descarregat, només cal executar el fitxer i seguir les instruccions a la pantalla.
            p.more-info
              a(href="https://support.tygron.com/wiki/Install") Més informació
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.start icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.start) Inicia una sessió de joc
          b-collapse.collapsed(id="start")
            p El primer pas és executar Tygron i iniciar sessió amb les credencials. Clica&nbsp;
              //b-button(v-b-modal.credentials variant="link") aquí
              a.link(v-b-modal.credentials) aquí
              span  per obtenir les credencials.
            p En alguns ordinadors l'arroba (@) no funciona. Si és el cas, pots prémer la tecla "Alt" i el número 64 del teclat numèric o pots escriure-la en un processador de text i fer copiar i enganxar.
            p Un cop iniciada la sessió, clica a "Sessions", després a "Start single" i selecciona {{sessionName}}. En cas de voler reobrir una sessió anterior, clica a "Load" i selecciona la data de la sessió guardada.
            b-img.m-3(src="../images/start-1.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.control icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.control) Menú de control
          b-collapse.collapsed(id="control")
            p Mentres jugues, pots obrir el menú de control utilitzant la tecla "ESC" del teclat. Això obrirà un menú amb les següents opcions:
            ul
              li Settings: Configura el volum i la qualitat gràfica.
              li Credits: Mostra els crèdits del joc.
              li Exit: Tanca la sessió.
              li Save: Guarda la sessió per continuar més endavant.
              li Export history: Descarrega un fitxer excel amb informació sobre les accions realitzades durant la sessió.
            b-img.m-3(src="../images/control-session.png" thumbnail center height="200")
      b-row
              b-col(cols="0")
                h5(class="coll-button")
                  b-icon(v-b-toggle.interface icon="chevron-up" size="sm")
              b-col()
                h5(class="coll-button")
                  a(type="button" v-b-toggle.interface) Interfície del joc
                b-collapse.collapsed(id="interface")
                  p La interfície del joc consisteix bàsicament en sis parts:
                  ol
                    li Un model 3D de la ciutat al centre de la pantalla on es visualitzen les accions per modificar-la.
                    li El menú d'accions a l'esquerra. Mostra les accions que es poden realitzar a la ciutat (construir, demolir, desfer...).
                    li El menú d'informació a la part superior esquerra. Mostra el nom de la sessió i l'escenari que s'està visualitzant a la dreta de la informació del jugador. L'original és l'escenari base i el planificat és l'escenari amb els canvis realitzats durant la sessió.
                    li Indicadors a la part superior de la pantalla. Són els indicadors calculats per al model de la ciutat. Clica sobre un indicador per obtenir informació detallada així com l'assoliment de l'objectiu.
                    li Menú de navegació a la part dreta. Permet moure el model 3D. També es pot utilitzar el ratolí i el teclat.
                    li Informació addicional a la dreta. La part principal són les capes sobreposades, que son capes d'informació que es poden visualitzar sobre el model 3D per obtenir una informació determinada, com les emissions de NO2, la distància a la zona verda més propera, els límits dels barris, etc.
                  b-img.m-3(src="../images/environment.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.budget icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.budget) Budget
          b-collapse.collapsed(id="budget")
            p El pressupost és una de les principals restriccions de la planificació urbana. Si el pressupost està en números vermells (has gastat més del que tenies), la puntuació baixa a zero. L'única opció llavors és desfer algunes accions fins a revertir els valors negatius del pressupost.
            p Clica sobre "Budget" per veure el pressupost de la sessió.
            b-img.m-3(src="../images/budget.png" thumbnail center)
            p.more-info
              a(href="https://support.tygron.com/wiki/Financial_system") Més informació
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions1 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions1) Accions
          b-collapse.collapsed(id="actions1")
            p Les accions es troben al menú de l'esquerra i s'executen directament sobre el model 3D. A continuació, es mostren les accions disponibles en una sessió individual:
            h5 Construïr
            p Permet construir en parcel·les buides. En sessions multijugador és necessari, a més, ser el propietari de la parcel·la. Pots trobar els elements disponibles per construir i la informació relacionada clicant sobre el interrogant, que obre aquest&nbsp;
              a(href="https://ediblecitygame.icradev.cat/") panell.
            b-img.action(src="../images/actions/ecs.png" alt="ECS comunitàries")
            b-img.action(src="../images/actions/prof_ecs.png")
            b-img.action(src="../images/actions/park.png")
            b-img.action(src="../images/actions/social-housing.png")

            h5 Millores
            p Les millores s'utilitzen per millorar un element existent. Per exemple, construir un jardí en un terrat. Clica sobre el interrogant per obtenir informació de totes les millores disponibles. Una vegada obert el panell, navega a la pestanya&nbsp;
              a(href="https://ediblecitygame.icradev.cat/upgrades.php") Millores.
            b-img.action(src="../images/actions/rooftop.png")
            b-img.action(src="../images/actions/fruit-trees.png")
            b-img.action(src="../images/actions/solar-panels.png")
            b-img.action(src="../images/actions/street.png")
            h5 Desfer
            p Desfer desfà les accions executades durant la sessió. Quan s'executa desfer, l'acció es desfà i el cost es restaura al pressupost.
            b-img.action(src="../images/actions/revert.png")
            h5 Demolir
            p Permet eliminar qualsevol element de la ciutat per, després, construir-ne un de nou a la mateixa parcel·la.
            b-img.action(src="../images/actions/demolish.png")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions2 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions2) Executar una acció
          b-collapse.collapsed(id="actions2")
            p Un cop seleccionada l'acció, les parcel·les disponibles per executar-la es ressalten. A continuació, s'ha de dibuixar l'àrea on s'executarà l'acció sobre el model 3D. Hi ha diverses maneres de dibuixar l'àrea:
            ul
              li Utilitzant una graella i seleccionant les cel·les
              li Dibuixant una línia amb un ample específic
                b  &#8592; aquesta és probablement l'opció més fàcil
              li Dibuixant un cercle
              li Seleccionant tota la parcel·la.
            b-img.m-3(src="../images/draw-action.png" thumbnail center)
            p.text-center Modificant el paràmetre "width", es pot ajustar la precisió del dibuix.
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.indicators icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.indicators) Indicadors
          b-collapse.collapsed(id="indicators")
            p Els indicadors són una manera de mesurar l'impacte de les accions sobre la ciutat. Cada acció executada té un impacte específic sobre els indicadors de la ciutat. Abans de confirmar l'acció, es pot veure l'impacte esperat.
            p Per veure els indicadors, clica sobre el nom d'un indicador per obrir el seu panell que conté més informació:
            ul
              li Una explicació sobre què mesura l'indicador
              li La puntuació de cada barri
              li El valor "to do" per arribar a l'objectiu marcat en el joc
              li Alguns consells sobre com millorar la puntuació de l'indicador
            b-img.m-3(src="../images/indicator.png" thumbnail center)

    b-modal(id="credentials" title="Tygron credentials" ok-only)
      b-img.m-3(src="../images/credentials.png" center)

</template>

<script>
import Header from '@/components/header.vue';

export default {
  name: 'Ajuda',
  components: {
    Header
  },
  computed: {
    sessionName(){
      if (window.location.host.includes("sfl")) return "edible_sfl"
      if (window.location.host.includes("spg")) return "edible_spg"
      if (window.location.host.includes("girona")) return "edible_girona"
      return "the session you are interested in"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }

  img {
    max-height: 600px;
  }

  .more-info {
    text-align:right;
  }

  .action {
    height: 50px;
    margin-bottom: 1em;
  }

  .link{
    cursor: pointer;
    color:  #3848fc ;
  }
  .link:hover{
    text-decoration: underline;
    color: #0056B3;
  }
</style>
