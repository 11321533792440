<template lang="pug">
  .generalBackground
    b-container(fluid="true")
      Header
      b-row
        b-col(cols="2")
        b-col(cols="8")
          Registrar
        b-col(cols="2")
          //Classificacio
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header.vue';
import Registrar from '@/components/registrar_ca.vue';
import Classificacio from '@/components/classificacio.vue';
import Ajuda from '@/components/ajuda.vue';

export default {
  name: 'Home',
  components: {
    Registrar,
    Classificacio,
    Ajuda,
    Header
  },
  data: function(){
    return {
      error: false,
      partida: false
    }
  }
}
</script>

<style>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 1rem 1rem 0;
    min-height: 600px;
  }

  a.nav-link {
    color: var(--green-primary) ;
    font-size: 18px;
  }

  a.nav-link:hover {
    color: var(--dark-red) ;
    font-size: 18px;
  }

  .nav-tabs .nav-link{
    background-color: white !important;
    border: 10px solid var(--light-gray-primary) !important;
    border-radius: 0 !important;
  }

  .nav-tabs .nav-link.active{
    background-color: white !important;
    border: 10px solid #fff !important;
    border-radius: 0 !important;
    color: var(--green-primary) !important;
  }

  .homeTitle {
    color: white;
    text-align: center;
  }

</style>
